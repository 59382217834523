.title-card {
  font-family: 'Montserrat Bold', sans-serif;
  color: #1281fb;
  text-align-last: center;
  position: absolute;
  font-size: 16px;
  margin-top: 10px;
}

.payment-grid {
  margin-top: 10%;
}

.card-style {
}

.pay-backbutton {
  position: absolute;
  top: 3%;
  left: 0%;
  z-index: 1000;
}

.text-opts {
  font-size: 16px;
  color: #6e7377;
  font-family: 'Montserrat Regular', sans-serif;
  text-align: center;
  margin-top: 30px;
  width: 80%;
}

.explanation {
  padding: 6px 0 6px 0;
  font-size: 12px;
  color: #1d1d1b;
  font-family: 'Montserrat Light', sans-serif;
  border: #e5e5e5 solid 2px;
  margin-top: 60px;
  width: 90%;
  border-radius: 2%;
  background-color: #f7f9fc;
}

.text-in {
  margin: 10px;
}
