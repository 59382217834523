.Toastify__toast-container {
  z-index: 99999;
  position: fixed;
  box-sizing: border-box;
  color: #fff;
  box-shadow: none;
  width: 100%;
  padding: 4px;
}

.Toastify__toast--success {
  background: #59caa8;
}

.Toastify__toast--error {
  background: #ff7777;
}

.Toastify__close-button {
  color: #fff;
}

.Toastify__toast-body {
  color: #fff;
  width: 100%;
}
