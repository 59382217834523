/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the `iban` element.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

input {
  height: 40px;
  padding: 10px 12px;

  color: #1d1c1a;
  background-color: white;

  box-shadow: none;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  border-bottom: 1px solid #1d1c1a;
  margin-top: 4px;
}

input:focus,
.StripeElement--focus {
  box-shadow: none;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
