.forgot-input {
  margin-top: 50%;
}
.forgot-title {
  text-align: center;
  margin-bottom: 50px;
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 22px;
  color: #1281fb;
}
