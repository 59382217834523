.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.pl-4 {
  padding: 0;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-left: 1rem;
}

.react-horizontal-scrolling-menu--item {
  padding-bottom: 8px;
}
