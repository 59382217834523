body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: 'liga' on;
}

.my-custom-class .alert-wrapper {
  z-index: 1000000000 !important;
  background-color: #fff;
  position: absolute;
}

.bum-button-round {
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 25px;
  text-transform: none;
}

.bum-button-circle {
  height: 60px;
  width: 60px;
  margin: 0px;
  padding: 0;
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 25px;
  text-transform: none;
}

.arrow-in-button {
  margin: 0;
  padding: 0;
}

.text-center-basic {
  font-family: 'Montserrat Bold', sans-serif;
  text-align: center;
  font-size: 12px;
}

.no-underline {
  text-decoration: none;
}
.content-back {
  margin-top: 50px;
}

.content-back-small {
  margin-top: 40px;
}

.centered-image {
  margin: 0 auto;
  width: 65%;
}

.hollow-button {
  color: #333333;
}

.main-image {
  margin-top: 30%;
  margin-bottom: 20%;
}

.bottom-controls {
  margin-top: 25%;
  width: 100%;
}

.limited-center {
  max-width: 411px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.backbutton {
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 1000;
}

.text-center {
  text-align: center;
}

.center {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.colorblue {
  color: #2181fb;
}

.filler {
  height: 80px;
}

.submit-enter {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

/* ############ new styles */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* Colors */
.azul_fondo {
  background-color: #d8e5ed;
}

.color_principal_bg {
  background-color: #322e8f;
}

.color_principal_text {
  color: #322e8f;
}

/* Fonts */
.titulo-app {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
}

.titulo-onboarding {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

.titulo-pantalla {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
}

.titulo-secundario-pantalla {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.texto-boton {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 0.7px;
}

.texto-principal-titulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.texto-principal-enlace {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.texto-principal {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.texto-secundario-titulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.texto-secundario {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.texto-descriptivo-small-titulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 12px;
}

.texto-descriptivo-small {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.texto-tags {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 10px;
}

.texto-codigo-qr {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 4.2px;
}

.blacktext {
  color: #000;
}

.centered-container {
  display: block;
  text-align: center;
}

.link {
  text-decoration: underline;
}

.input-icon {
  align-self: center;
  font-size: 16px;
}
