.autocomplete-input {
  padding-left: 0px !important;
}

.autocomplete-container {
  background-color: red;
}
.autocomplete-dropdown-container {
    color: black
}
