.toolbar-background {
  background-color: blue;
}

.login-divider {
  padding-top: 25px;
}

.social-login-row {
  padding-top: 20px;
}

.btn-social-login {
  --border-color: #1d1c1a;
  --color: #1d1c1a;
  text-align: left;
}

.social-login-button-logo {
  width: 18px;
  height: 18px;
  margin-right: 19px;
}
